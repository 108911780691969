<template>
  <el-dialog
    top="30px"
    fullscreen
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t("dealer_finance.detailDialog") }}
    </div>
    <div style="width: 100%; height: 100%">
      <el-tabs
        v-model="activeName"
        @tab-click="handleTabClick"
      >
        <el-tab-pane
          :label="$t('dealer_finance.balance.pageTitle')"
          name="balance"
        >
          <balance
            ref="balance"
            :detail-data="detailData"
            :head-info="headInfo"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('dealer_finance.incomeStatement.pageTitle')"
          name="incomeStatement"
        >
          <income-statement
            :detail-data="detailData"
            :head-info="headInfo"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('dealer_finance.adjustingEntries.pageTitle')"
          name="adjustingEntries"
        >
          <adjusting-entries
            ref="adjustEntries"
            :editable="!viewFlag"
            :detail-data="detailData"
            :head-info="headInfo"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('dealer_finance.operatingData.pageTitle')"
          name="operatingData"
        >
          <operating-data
            :editable="!viewFlag"
            :detail-data="detailData"
            :head-info="headInfo"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('dealer_finance.newVehicleRetail.tableTitle')"
          name="newVehicleRetail"
        >
          <new-vehicle-retail
            :editable="!viewFlag"
            :detail-data="detailData"
          />
        </el-tab-pane>
        <!-- <el-tab-pane
          :label="$t('dealer_finance.newVehicleNotRetail.tableTitle')"
          name="newVehicleNotRetail"
        >
          <new-vehicle-not-retail
            :editable="!viewFlag"
            :detail-data="detailData"
          />
        </el-tab-pane> -->
        <el-tab-pane
          :label="$t('dealer_finance.notNewVehicleVme.tableTitle')"
          name="notNewVehicleVme"
        >
          <not-new-vehicle-vme
            :editable="!viewFlag"
            :detail-data="detailData"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('dealer_finance.newVehicleRetailProfit.tableTitle')"
          name="newVehicleRetailProfit"
        >
          <new-vehicle-retail-profit :detail-data="detailData" />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('dealer_finance.usedCarBusiness.tableTitle')"
          name="usedCarBusiness"
        >
          <used-car-business
            :editable="!viewFlag"
            :detail-data="detailData"
            :head-info="headInfo"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div slot="footer">
      <!-- <el-button
        v-show="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button> -->
      <el-button @click="currentShowFlag = false">
        {{ viewFlag ? $t("operation.close") : $t("operation.cancel") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import balance from './sheets202308/balance'
import incomeStatement from './sheets202308/incomeStatement'
import adjustingEntries from './sheets202308/adjustingEntries'
import operatingData from './sheets202308/operatingData'
import newVehicleRetail from './sheets202308/newVehicleRetail'
// import newVehicleNotRetail from './sheets202308/newVehicleNotRetail'
import notNewVehicleVme from './sheets202308/notNewVehicleVme'
import newVehicleRetailProfit from './sheets202308/newVehicleRetailProfit'
import usedCarBusiness from './sheets202308/usedCarBusiness'

const BASEURL = {
  get: 'jlr/tt/selfExaminationData/get',
  balance: '/jlr/tt/balanceSheet/wrapper',
  submit: '/jlr/tt/selfExaminationData/submitData'
}

export default {
  name: 'DealerFinanceDetail',
  components: {
    balance,
    incomeStatement,
    adjustingEntries,
    operatingData,
    newVehicleRetail,
    // newVehicleNotRetail,
    notNewVehicleVme,
    newVehicleRetailProfit,
    usedCarBusiness
  },
  props: {
    showFlag: {
      type: Boolean,
      default: false
    },
    viewFlag: {
      type: Boolean
    },
    detailData: {
      type: Object
    },
    isThirdParty: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      headInfo: {},
      activeName: ''
    }
  },
  inject: [ 'handleDataSearch' ],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    status () {
      return this.$getDictList('data_status')
    },
    formField () {
      return [
        {
          prop: 'name',
          type: 'Input',
          label: this.$t('dealer_finance.name'),
          component: {
            clearable: true
          },
          col: {
            xs: 12,
            sm: 12,
            md: 12
          },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'code',
          type: 'Input',
          label: this.$t('dealer_finance.code'),
          component: {
            clearable: true
          },
          col: {
            xs: 12,
            sm: 12,
            md: 12
          },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'type',
          type: 'Select',
          label: this.$t('dealer_finance.type'),
          col: {
            xs: 12,
            sm: 12,
            md: 12
          },
          component: {
            clearable: true,
            optionList: this.$getDictList('mt_kpi_type')
          },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },
        {
          prop: 'businessArea',
          type: 'Select',
          label: this.$t('dealer_finance.businessArea'),
          col: {
            xs: 12,
            sm: 12,
            md: 12
          },
          component: {
            clearable: true,
            optionList: this.$getDictList('mt_kpi_business_area')
          },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        },

        {
          prop: 'calculationFormula',
          type: 'Input',
          label: this.$t('dealer_finance.calculationFormula'),
          component: {
            clearable: true
          },
          col: {
            xs: 24,
            sm: 24,
            md: 24
          },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        }
      ]
    }
  },
  methods: {
    handleDataSubmit () {
      // 借贷未平，不能提交
      if (!this.$refs.adjustEntries.isEqual) {
        return this.$message({
          type: 'warning',
          message: this.$t('dealer_finance.adjustingEntries.validateTip3')
        })
      }
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('dealer_finance.submitTip'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            const loadingFlag = this.$loading({
              target: this.$el.querySelector('.el-dialog')
            })
            this.$axios
              .post(BASEURL.submit, {
                id: this.headInfo.id,
                copyNewVersion: !this.isThirdParty
              })
              .then((resp) => {
                done()
                this.currentShowFlag = false
                this.$message({
                  type: 'success',
                  message: this.$t('tip.approveSuccess')
                })
              })
              .finally(() => {
                loadingFlag.close()
              })
          } else done()
        }
      }).then(() => { })
    },

    handleTabClick (pane) {
      if (
        pane.$children &&
        pane.$children[ 0 ] &&
        pane.$children[ 0 ].handleDataSearch
      ) {
        pane.$children[ 0 ].handleDataSearch()
      }
    },

    handleDialogOpen () {
      this.activeName = !this.viewFlag ? 'adjustingEntries' : 'balance'
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(BASEURL.get, {
          id: this.detailData.id
        })
        .then((resp) => {
          this.headInfo = resp.data
        })
        .finally(() => {
          loadingFlag.close()
        })
      this.$nextTick(() => {
        this.$refs.balance.handleDataSearch()
        this.$refs.adjustEntries.handleDataSearch()
      })
    },

    handleDialogClosed () { }
  }
}
</script>
